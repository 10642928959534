<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入道具名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
        <!-- <el-button
          size="medium"
          type="primary"
          icon="el-icon-upload2"
          @click="addBatchFunc"
          >批量上传</el-button
        >
        <el-button
          size="medium"
          type="info"
          icon="el-icon-refresh"
          @click="refreshFunc"
          >更新</el-button
        > -->
      </div>
    </div>
    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id" width="80"></el-table-column>
      <el-table-column prop="tokenId" label="文件id" width="80"></el-table-column>
      <el-table-column prop="name" label="名称" width="200"></el-table-column>
      <el-table-column prop="categoryId" label="类型" width="200">
        <template slot-scope="scope">
          {{typeOptions[scope.row.categoryId]}}
        </template>
      </el-table-column>
      <el-table-column prop="image" label="图片">
        <template slot-scope="scope">
          <img :width="100" :src="scope.row.image" class="icon" />
        </template>
      </el-table-column>
      <el-table-column prop="image" label="图片地址"></el-table-column>
      <el-table-column prop="json" label="json"></el-table-column>
      <el-table-column prop="jsonIpfsUrl" label="json地址"></el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog title="新建道具文件" :visible.sync="addVisible" width="700px" top="3vh">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="name" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="描述">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="ruleForm.description">
            </el-input>
        </el-form-item>
        <el-form-item label="类型" prop="categoryId" :rules="rules.changeRule">
          <el-select
            size="medium"
            clearable
            v-model="ruleForm.categoryId"
            placeholder="类型"
          >
            <el-option
              v-for="(item,key) in typeOptions"
              :key="key"
              :label="item"
              :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文件Id" prop="tokenId" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.tokenId"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片" prop="imageIpfsUrl" :rules="rules.typeIcon">
          <uploadImg 
            :disabled="!ruleForm.categoryId||!ruleForm.tokenId"  
            @change="uploadImgChange" 
            :imageFile="[{url:ruleForm.imageIpfsUrl}]" 
            :reqType="reqType"
            uploadApiUrl="/nft/upload/nft-image">
          </uploadImg>
        </el-form-item>
        <el-form-item label="操作" prop="name" :rules="rules.blurRule">
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleJson">生成json</el-button>
        </el-form-item>
        <el-form-item label="json">
            <el-input
              type="textarea"
              :rows="5"
              disabled
              v-model="ruleForm.json">
            </el-input>
        </el-form-item>
        <el-form-item label="json地址">
          <el-input
            disabled
            v-model="ruleForm.jsonIpfsUrl"
          ></el-input>
        </el-form-item>
        <el-form-item label="图片地址">
          <el-input
            disabled
            v-model="ruleForm.imageIpfsUrl"
          ></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="批量新建道具文件" :visible.sync="addBatchVisible" width="700px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
      >
        <el-form-item label="类型" prop="categoryId" :rules="rules.changeRule">
          <el-select
            size="medium"
            clearable
            v-model="ruleForm.categoryId"
            placeholder="类型"
          >
            <el-option
              v-for="(item,key) in typeOptions"
              :key="key"
              :label="item"
              :value="key">
            </el-option>
          </el-select>
        </el-form-item>
        <p style="margin-bottom:20px">文件名以 <b>tokenID-名称-描述.png</b> 作为格式，例如：111-name-description.png</p>
        <el-form-item label="图片（支持多选）" prop="img" :rules="rules.typeIcon">
          <uploadImg 
            :disabled="!ruleForm.categoryId||!ruleForm.role"  
            @change="uploadImgBatchChange" 
            :multiple="true"
            uploadApiUrl="/nft/upload/nft-image">
          </uploadImg>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="addBatchVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitBatchFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      addBatchVisible:false,
      ruleForm: {
        id:"",
        name: "",
        description:"",
        img:"",
        category:""
      },
      searchForm: {
        pageNo: 1,
        pageSize: 10
      },
      typeOptions: {
        '1':'1.鞋',
        '2':'2.电池',
        '4':'4.钻石',
        '8':'8.书',
      },
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  computed:{
    reqType(){
      const {categoryId, tokenId} = this.ruleForm
      let reqType = {
        path:`${categoryId}`,
        id:tokenId,
        type:'IMAGE'
      }
      return reqType
    },
  },
  created() {
    this.getTableList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {};
      this.addVisible = true;
    },
    addBatchFunc(){
      this.ruleBatchForm = {};
      this.addBatchVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.imageIpfsUrl=val[0].url
        this.$forceUpdate();
      }
    },
    uploadImgBatchChange(val){
      console.log(val)
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.propsList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.propsSave({...this.ruleForm});
          if (res) {
            this.$message.success("修改成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async handleJson() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          const {categoryId, tokenId} = this.ruleForm
          const ipfsJson = {}
          ipfsJson.attributes = []
          ipfsJson.attributes.push({ 'trait_type': 'categoryId', 'value': this.ruleForm.categoryId })
          ipfsJson.image = this.ruleForm.imageIpfsUrl
          ipfsJson.extend_info = {}
          ipfsJson.animation_url = ''
          ipfsJson.background_color = ''
          ipfsJson.name = this.ruleForm.name
          ipfsJson.description = this.ruleForm.description
          const jsonString = JSON.stringify(ipfsJson, null, 2)// 2表示缩进两个空格
          this.ruleForm.json = jsonString
          let form = {}
          form.json = jsonString
          form.id = this.ruleForm.tokenId
          form.path=`json/${categoryId}`
          let res = await this.$http.ipfsUploadJson({...form});
          if (res) {
            this.ruleForm.jsonIpfsUrl = res.data.url;
            this.$forceUpdate();
          }
        }
      });
    },
    refreshFunc() {
      this.$confirm(`确定要刷新缓存么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.ipfsRefresh()
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    async submitBatchFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          this.getTableList();
          this.addBatchVisible = false;
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
